/* src/components/TermsAndConditions.css */
.terms-conditions-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .terms-conditions-box {
    width: 100%;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  
  .terms-conditions-box h2 {
    text-align: center;
  }
  
  .terms-conditions-text {
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 10px;
    overflow-y: scroll;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .signature-canvas-container {
    border: 1px solid #ccc;
    width: 100%;
    height: 100%; /* Ensure it takes the full height */
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
  }
  
  .signature-canvas-container .sigCanvas {
    width: 100% !important;
    height: 100% !important;
  }
  
  .signature-buttons {
    display: flex;
    justify-content: space-between;
  }
  